import React, { useEffect } from "react"
import Logo from "../components/Logo"
import { Link } from "gatsby"
import Seo from "../components/seo"

const NotFoundPage = () => {
  useEffect(() => {
    if (window !== "undefined" && window.location.href) {
      var currentLink = window.location.href
      if (currentLink.indexOf("/be")) {
        // console.log(currentLink.substring(currentLink.indexOf("/be/") + 3))
        window.location.href = currentLink.substring(
          currentLink.indexOf("/be/") + 3
        )
      }
    }
  })
  return (
    <>
      <div className="min-h-144 py-32 px-8 max-w-120 mx-auto">
        <Seo title="404: page non trouvée" />
        <h1 className="font-bold">Erreur 404 page non trouvée</h1>
        <p>
          <Link className="text-xl" to="/">
            Retourner à la page d'accueil du site
          </Link>
        </p>
        <div className="max-w-80">
          <Logo mod="big" color="#1c1917" color2="#4C4C4C" />
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
